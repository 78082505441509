<template>
  <div class="relative w-5 h-5">
    <svg class="w-full h-full" viewBox="0 0 100 100">
      <!-- Background circle -->
      <circle
        class="text-gray-200 stroke-current"
        stroke-width="20"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
      ></circle>
      <!-- Progress circle -->
      <circle
        :class="['progress-ring__circle stroke-current', color]"
        stroke-width="20"
        stroke-linecap="round"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        stroke-dasharray="251.2"
        :stroke-dashoffset="radialProgress"
      ></circle>
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  color: {
    type: String,
    default: "text-primary-400",
  },
  progress: {
    type: Number,
    required: true,
  },
  minProgress: {
    type: Number,
    default: 0,
  },
});

const radialProgress = computed(() => {
  // Ref: https://gist.github.com/eYinka/873be69fae3ef27b103681b8a9f5e379
  return Math.min(
    251.2 - (251.2 * props.progress) / 100,
    251.2 - (251.2 * props.minProgress) / 100,
  );
});
</script>

<style scoped>
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
